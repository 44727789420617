import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import AssetBrowser from 'common/components/AssetBrowser';
import * as constants from 'common/components/AssetBrowser/lib/constants';
import { RENDER_STYLE_LIST } from 'common/components/AssetBrowser/lib/constants';
import * as ceteraHelpers from 'common/components/AssetBrowser/lib/helpers/cetera';

import Dropdown from 'common/components/Dropdown';

class AssetBrowserAdminAssetsDemo extends Component {

  render() {
    const columns = [
      constants.COLUMN_TYPE,
      constants.COLUMN_NAME,
      constants.COLUMN_ACTIONS,
      constants.COLUMN_LAST_UPDATED_DATE,
      constants.COLUMN_CATEGORY,
      constants.COLUMN_OWNER,
      constants.COLUMN_AUDIENCE
    ];

    const dropDownProps = {
      disabled: false,
      id: 'demo-custom-tab-compoent',
      onSelection: (option) => alert(JSON.stringify(option)),
      options: [
        { title: 'No Value', value: null },
        { title: 'A Value', value: 1 },
        { title: 'Another Value', value: 'ha!' }
      ],
      placeholder: 'Make a selection...',
      value: 'value'
    };

    const forUser = ceteraHelpers.getCurrentUserId();
    const sharedTo = ceteraHelpers.getCurrentUserId();

    const tabs = {
      [constants.TAB_ALL_ASSETS]: {
        props: {
          columns
        }
      },
      [constants.TAB_MY_ASSETS]: {
        props: {
          baseFilters: {
            forUser
          },
          columns
        }
      },
      [constants.TAB_SHARED_TO_ME]: {
        props: {
          baseFilters: {
            sharedTo
          },
          columns
        }
      },
      [constants.TAB_SETTINGS]: {
        component: Dropdown,
        props: {...dropDownProps}
      }
    };

    const assetBrowserProps = {
      initialTab: constants.TAB_ALL_ASSETS,
      renderStyle: RENDER_STYLE_LIST,
      showAssetCounts: true,
      showFilters: true,
      showHeader: true,
      showSearchField: true,
      tabs
    };

    return (
      <div>
        <div className="styleguide-example">
          <AssetBrowser {...assetBrowserProps} />
        </div>

        <h2 className="styleguide-header">Code example</h2>
        <div className="styleguide-code-example">
          <pre>
            {`
const assetBrowserProps = ${JSON.stringify(assetBrowserProps, null, 2)};

<AssetBrowser {...assetBrowserProps} />
            `}
          </pre>
        </div>
      </div>
    );
  }
}

$(() => {
  ReactDOM.render(
    React.createElement(AssetBrowserAdminAssetsDemo),
    document.getElementById('component-demo-admin-assets')
  );
});
